<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useToast } from "primevue/usetoast"
import { useActivitiesStore } from "@/store/activity"

const route = useRoute()
const router = useRouter()
const toast = useToast()

const activityStore = useActivitiesStore()
const activity = reactive({})

const mainActivity = reactive({})

const creating = ref(false)

const optionRegister = [
	{ label: "Open", value: true },
	{ label: "Close", value: false }
]

const optionActive = [
	{ label: "Active", value: true },
	{ label: "Deactive", value: false }
]
const loading = ref(false)
const uploadDisabled = ref(false)
const readonly = ref(false)

let oldPicture = null
const uploader = (event) => {
	uploadDisabled.value = true
	const reader = new FileReader()
   reader.readAsDataURL(event.files[event.files.length - 1])
   reader.onload = function () {

    activity['picture'] = reader.result
		uploadDisabled.value = false
   }
   reader.onerror = function () {
		uploadDisabled.value = false
   }
}

const uploadRemove = () => {
	if (oldPicture !== null) activity['picture'] = oldPicture
	else delete activity['picture']
}

const save = () => {
	const action = creating.value
		? activityStore.store(activity)
		: activityStore.update(route.params.id, activity)
	loading.value = true
	action.then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
			loading.value = false
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

		oldPicture = data.activity.picture_url

		if (creating.value) {
			activity['picture_url'] = data.activity.picture_url

			setTimeout(() => {
				router.replace({ name: 'activities.edit', params: { id: data.activity.id } })
				creating.value = false
				loading.value = false
			}, 3000)

			return
		}

		loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

const saveMainActivity = () => {
	loading.value = true
	activityStore.updateMainActivity(route.params.id, mainActivity).then(({ data }) => {
		loading.value = false

		if (data.error) {
			toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
			return
		}

		toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

	}).catch((e) => {
		toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
	})
}

onMounted(() => {
	creating.value = (typeof route.params.id === 'undefined' && typeof activity.id === 'undefined')
	readonly.value = route.meta.breadcrumb[0].readonly ?? false
	if (typeof route.params.id !== 'undefined') {
		loading.value = true
		activityStore.show(route.params.id)
			.then((res) => {
				const _activity = res.data.activity

				activity['id'] = _activity.id
				activity['picture_url'] = _activity.picture_url
				activity['link_url'] = _activity.link_url
				activity['title_heading'] = _activity.title_heading
				activity['title_registration'] = _activity.title_registration
				activity['title_compete'] = _activity.title_compete
				activity['title_rewards'] = _activity.title_rewards
				activity['is_register'] = _activity.is_register
				activity['is_active'] = _activity.is_active

				activity['picture'] = activity['picture_url']
				oldPicture = activity['picture_url']

				mainActivity['heading'] = _activity.heading
				if (_activity.finished_at === null) {
					mainActivity['finished_at'] = new Date()
				} else {
					mainActivity['finished_at'] = new Date(_activity.finished_at)
				}
				
				mainActivity['description'] = _activity.description
				mainActivity['is_main_activity'] = _activity.is_main_activity

				loading.value = false
			})
			.catch(() => loading.value = false)
	}
})

</script>
<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="activity.id" class="block-title">
            <div>Show #{{ activity.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>
    <div class="block-content">
			<div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
				<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
					<div>
						<Image v-if="activity.picture" :src="activity.picture" preview alt="Image Text" class="mb-3" style="width: 100%"/>
						<FileUpload
							v-if="!readonly"
							accept="image/*"
							:customUpload="true"
							:auto="true"
							:showUploadButton="false"
							:disabled="uploadDisabled"
							@uploader="uploader"
							@clear="uploadRemove"
						>
						</FileUpload>

						<label class="block text-900 font-medium mb-2" for="link">Link</label>
						<InputText v-model="activity.link_url" id="link" type="text" class="w-full mb-3" :readonly="readonly"/>

						<label class="block text-900 font-medium mb-2" for="heading">Heading</label>
						<InputText v-model="activity.title_heading" id="heading" type="text" class="w-full mb-3" :readonly="readonly" />

						<label class="block text-900 font-medium mb-2" for="registration">Registration</label>
						<InputText v-model="activity.title_registration" id="registration" type="text" class="w-full mb-3" :readonly="readonly" />

						<label class="block text-900 font-medium mb-2" for="compete">Compete</label>
						<InputText v-model="activity.title_compete" id="compete" type="text" class="w-full mb-3" :readonly="readonly"/>

						<label class="block text-900 font-medium mb-2" for="rewards">Rewards</label>
						<InputText v-model="activity.title_rewards" id="rewards" type="text" class="w-full mb-3" :readonly="readonly"/>

						<label class="block text-900 font-medium mb-2" for="is_register">Register</label>
						<SelectButton v-model="activity.is_register" id="is_register" class="w-full mb-3" :options="optionRegister" optionLabel="label" optionValue="value" :disabled="readonly"/>

						<label class="block text-900 font-medium mb-2" for="is_active">Active</label>
						<SelectButton v-model="activity.is_active" id="is_active" class="w-full mb-3" :options="optionActive" optionLabel="label" optionValue="value" :disabled="readonly"/>

					</div>

					<br/>

					<Button v-if="!readonly" label="Save" class="w-full" :loading="loading" @click="save()" />
				</div>
			</div>
    </div>

    <div v-if="! creating" class="block-content">
			<div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
				<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
					<div>
						<label class="block text-900 font-medium mb-2" for="heading">Heading</label>
						<InputText v-model="mainActivity.heading" id="heading" type="text" class="w-full mb-3" :readonly="readonly"/>

						<label class="block text-900 font-medium mb-2" for="finished_at">Finished At</label>
						<InputText v-if="readonly" 
							v-model="activity.finished_at" id="finished_at" type="text" class="w-full mb-3" readonly />
						<Calendar v-else
							v-model="mainActivity.finished_at" 
							
							class="w-full mb-3"
							dateFormat="yy-mm-dd"
							id="finished_at"
							:showSeconds="true"
							:showTime="true"
						/>

						<label class="block text-900 font-medium mb-2" for="description">Description</label>
						<Textarea v-model="mainActivity.description" id="description" rows="5" class="w-full mb-3" :readonly="readonly" />

						<label class="block text-900 font-medium mb-2" for="is_main_activity">Main Activity</label>
						<InputSwitch v-model="mainActivity.is_main_activity" id="is_main_activity" class="mb-3" :disabled="readonly" />
						
					</div>

					<br/>

					<Button v-if="!readonly" label="Save" class="w-full" :loading="loading" @click="saveMainActivity()" />
				</div>
			</div>
    </div>
  </div>
</template>
